export const USAGES = [
  "networking",
  "recruitment",
  "foreign_worker_management",
  "foreign_relations",
  "marketing",
  "education_training",
];
export const DESCRIPTIONS = [
  "recruiting_agency",
  "business",
  "law_firm",
  "government_agency",
  "travel_agency",
  "educational_institution",
];
export const COLORS = [
  "#9e0142",
  "#d53e4f",
  "#f46d43",
  "#fdae61",
  "#fee08b",
  "#e6f598",
  "#abdda4",
  "#66c2a5",
  "#3288bd",
  "#5e4fa2",
];
export const LANGUAGES: { [key: string]: string } = {
  English: "en",
  Estonian: "et",
  Filipino: "fil",
  Finnish: "fi",
  French: "fr",
  German: "de",
  Greek: "el",
  Gujarati: "gu",
  Hebrew: "iw",
  Hindi: "hi",
  Hungarian: "hu",
  Icelandic: "is",
  Indonesian: "id",
  Italian: "it",
  Japanese: "ja",
  Kannada: "kn",
  Korean: "ko",
  Latvian: "lv",
  Lithuanian: "lt",
  Malay: "ms",
  Malayalam: "ml",
  Marathi: "mr",
  Mongolian: "mn",
  Norwegian: "no",
  Polish: "pl",
  "Portuguese (Portugal)": "pt-PT",
  Romanian: "ro",
  Russian: "ru",
  Serbian: "sr",
  "Chinese (PRC)": "zh-CN",
  Slovak: "sk",
  Slovenian: "sl",
  Spanish: "es",
  Swahili: "sw",
  Swedish: "sv",
  Tamil: "ta",
  Telugu: "te",
  Thai: "th",
  "Chinese (Taiwan)": "zh-TW",
  Turkish: "tr",
  Urdu: "ur",
  Ukrainian: "uk",
  Vietnamese: "vi",
  Welsh: "cy",
};

export const REPORT_REASONS: { [key: string]: string } = {
  "harassment-or-bullying": "Harassment or Bullying",
  "adult-content": "Adult Content",
  "hate-speech-or-discrimination": "Hate Speech or Discrimination",
  "spam-or-scams": "Spam or Scams",
  "violence-or-threats": "Violence or Threats",
  "copyright-violation": "Copyright Violation",
  "false-information": "False Information",
  "privacy-concerns": "Privacy Concerns",
  "irrelevant-content": "Irrelevant Content",
};

export const PUBLIC_PAGES: string[] = [
  "/login",
  "/signup",
  "/terms",
  "/soon",
  "/privacy",
  "/forgot-password",
  "/public/forum",
  "/public/community",
  "/public/forum/",
  "/public/community/",
];

export const MODULES: { name: string; icon: string; link: string }[] = [
  { name: "home", icon: "iconoirHome", link: "/" },
  {
    name: "workspace",
    icon: "iconoirNetwork",
    link: "/workspace",
  },
  {
    name: "opportunities",
    icon: "iconoirJournalPage",
    link: "/opportunities",
  },
  {
    name: "tasks",
    icon: "iconoirMultiplePages",
    link: "/tasks",
  },
  {
    name: "content",
    icon: "iconoirMediaImageFolder",
    link: "/content",
  },
  {
    name: "collaborators",
    icon: "iconoirGroup",
    link: "/collaborators",
  },
  {
    name: "roadmap",
    icon: "iconoirMap",
    link: "/roadmap",
  },
  // {
  //   name: "questionnaire",
  //   icon: "iconoirOpenBook",
  //   link: "/questionnaire",
  // },
  {
    name: "company",
    icon: "iconoirBuilding",
    link: "/company",
  },
  {
    name: "messages",
    icon: "iconoirMessage",
    link: "/messages",
  },
  {
    name: "forum",
    icon: "ionDiscOutline",
    link: "/forum",
  },
  {
    name: "user-list",
    icon: "iconoirHouseRooms",
    link: "/user-list",
  },
];
